$light-gray: #ced9e0;
$dark-gray: #5c7080;
$skeleton-color-start: rgba($light-gray, 0.2);
$skeleton-color-end: rgba($dark-gray, 0.2);

@keyframes skeleton-glow {
    from {
        background: $skeleton-color-start;
        border-color: $skeleton-color-start;
    }

    to {
        background: $skeleton-color-end;
        border-color: $skeleton-color-end;
    }
}

// This class hides content with a glowing, rounded rectangle.
// CSS properties that should always override consumer values use the "!important" rule.
/* stylelint-disable declaration-no-important */
.skeleton {
    animation: skeleton-glow 1s linear infinite alternate;

    // do not !important this for Firefox support
    background: $skeleton-color-start;

    // Prevent background color from extending to the border and overlappping
    background-clip: padding-box !important; // sass-lint:disable-line no-important
    border-color: $skeleton-color-start !important; // sass-lint:disable-line no-important
    border-radius: 2px;
    box-shadow: none !important; // sass-lint:disable-line no-important

    // Transparent text will occupy space but be invisible to the user
    color: transparent !important; // sass-lint:disable-line no-important
    cursor: default;
    pointer-events: none;
    user-select: none;

    // Make pseudo-elements (CSS icons) and children invisible
    &::before,
    &::after,
    * { // sass-lint:disable-line no-universal-selectors
        visibility: hidden !important; // sass-lint:disable-line no-important
    }
}
