// Arrows for things like popovers and tooltips
$spacing--arrow: 10px;

@mixin arrow-top($spacing: $spacing--arrow) {
    border-width: $spacing $spacing 0;
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    bottom: -($spacing - 1px);
    left: calc(50% - #{$spacing});
    margin-top: 0;
    margin-bottom: 0;
}

@mixin arrow-bottom($spacing: $spacing--arrow) {
    border-width: 0 $spacing $spacing;
    border-left-color: transparent;
    border-right-color: transparent;
    border-top-color: transparent;
    top: -($spacing - 1px);
    left: calc(50% - #{$spacing});
    margin-top: 0;
    margin-bottom: 0;
}

@mixin arrow-left($spacing: $spacing--arrow) {
    border-width: $spacing $spacing $spacing 0;
    border-left-color: transparent;
    border-top-color: transparent;
    border-bottom-color: transparent;
    left: -($spacing - 1px);
    top: calc(50% - #{$spacing});
    margin-left: 0;
    margin-right: 0;
}

@mixin arrow-right($spacing: $spacing--arrow) {
    border-width: $spacing 0 $spacing $spacing;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    right: -($spacing - 1px);
    top: calc(50% - #{$spacing});
    margin-left: 0;
    margin-right: 0;
}
