// sass-lint:disable no-combinators, no-universal-selectors

//  Variables
//  ---------
// app-header = app-header__nav + app-header__base
$height--header: 56px + 64px;

//  Structure Modified
//  ----------

.w-tko-component {
  &--height-to-fit {
    & > *:first-child {
      min-height: 100%;
    }
  }

  &--height-to-viewport {
    height: 100vh;
    overflow: auto;
  }

  &--height-to-app-body {
    height: calc(100vh - #{$height--header});
    overflow: auto;
  }

  // sometimes the container restricts overlays due to having a `position: relative;`
  // so we need it to use a parent instead
  // https://digitalcrew.teamwork.com/#/tasks/16244129
  &--static {
    &,
    &.tipped-container {
      position: static;
    }
  }
}
