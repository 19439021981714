@import '~@tko/src/styles/variables/_color.variables';

.u-quick-view-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 119;
    overflow-x: hidden;
    overflow-y: auto;
    transform-style: preserve-3d;
    display: flex;
    justify-content: center;
    align-items: center;

    &__overlay {
        background-color: rgba($modal-backdrop--background-color, $modal-backdrop--opacity);
    }
}
