@import '~@tko/src/styles/variables/_color.variables';
@import '~styles/variables/z-index';

.u-modal-container {
    background-color: rgba($modal-backdrop--background-color, $modal-backdrop--opacity);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $z-index-modal;
    overflow-x: hidden;
    overflow-y: auto;
    transform-style: preserve-3d;
    display: flex;
    justify-content: center;
    align-items: center;
}
